<template>
  <v-container>
    <v-row justify="center" align-content="center">
      <v-col>
        <hos-progress-circular overlay :loading="loading"></hos-progress-circular>
      </v-col>
    </v-row>
    <div class="pdf-viewer mb-10">
      <div id="mypdf" class="pdf-viewer"></div>
    </div>
  </v-container>
</template>

<script>
import HosProgressCircular from "@/components/UI/HosProgressCircular";
import PDFObject from 'pdfobject';

export default {
  name: "ShowClientReceipt",
  components: { HosProgressCircular },
  props: {
    id: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      loading: true,
      pdf: undefined
    };
  },
  methods: {
    blobToBase64(blob) {
      return new Promise((resolve, _) => {
        console.log(_)
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },

    async getReceiptInformation() {
      this.loading = true;
      try {
        let resp = await this.$axios.get(
          `/Other/Receipt/GetClientReceipt?data=${this.id}`);
        console.log(resp)
        this.pdf = resp.data
        const options = {
          title: "Маршрут-квитанция",
        };
        if (this.pdf != undefined)
          PDFObject.embed(
            'data:application/pdf;base64,' + this.pdf,
            '#mypdf',
            options
          );
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    this.getReceiptInformation();
  },
};
</script>

<style scoped>
.pdf-viewer {
  height: 680px;
}
</style>
